import React from "react";
import "./TermsOfService.scss";
import { Link } from "react-router-dom";

function TermsOfService() {
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI日記 利用規約</h1>
      </div>

      <div className="content-area">
        <p>最終更新日: 2024/9/10</p>
        <br />
        <p>
          本利用規約（以下「本規約」といいます）は、aidiary（以下「開発者」といいます）が提供するスマートフォンアプリ「AI
          Diary」（以下「本アプリ」といいます）の利用に関する条件を定めるものです。本アプリを利用することで、本規約に同意したものとみなされます。
        </p>

        <h2>第1条（適用）</h2>
        <ol>
          <li>
            本規約は、ユーザーが本アプリを利用する際の一切の行為に適用されます。
          </li>
          <li>
            本規約の内容は、開発者の判断により随時変更されることがあります。本規約が変更された場合、変更後の規約は本アプリ内に掲示された時点から効力を生じます。
          </li>
        </ol>

        <h2>第2条（利用資格）</h2>
        <ol>
          <li>
            未成年（みせいねん）の方（かた）は保護者（ほごしゃ）の同意（どうい）を得（え）た上（うえ）で本（ほん）アプリを利用（りよう）するものとします。
          </li>
          <li>
            ユーザーは、本アプリを利用するにあたり、真実かつ正確な情報を提供するものとします。
          </li>
        </ol>

        <h2>第3条（利用料金および課金）</h2>
        <ol>
          <li>
            本アプリの基本機能は無料で利用できますが、今後、有料による追加機能の提供を行う可能性があります。
          </li>
          {/*<li>
            本アプリの基本機能は無料で利用できますが、プレミアム機能の利用にはサブスクリプションが必要です。
          </li>
          <li>
            サブスクリプションにより、広告が非表示となり、追加のプレミアム機能が利用可能になります。
          </li>
          <li>
            サブスクリプションの料金、支払い方法、および解約方法については、本アプリ内の説明に従ってください。
          </li> */}
        </ol>

        <h2>第4条（データの保存および利用）</h2>
        <ol>
          <li>
            ユーザーの日記データはサーバーに保存されます。ユーザーはこの点を十分に理解した上で利用するものとします。
          </li>
          <li>
            保存された日記データは、生成AIの学習目的で使用される可能性があります。
          </li>
          <li>
            開発者は、保存されたデータの正確性、完全性、または安全性を保証しません。
          </li>
        </ol>

        <h2>第5条（AIによる文章生成）</h2>
        <ol>
          <li>
            本アプリは、AIによる文章生成機能を提供しています。ユーザーは、生成された文章が必ずしも正確であるとは限らないことを理解し、これに同意するものとします。
          </li>
          <li>
            生成された文章には虚偽の情報が含まれる可能性があります。開発者は、生成された文章の内容に関していかなる保証も行わず、また、その内容に基づいてユーザーまたは第三者に生じた損害について一切の責任を負いません。
          </li>
        </ol>

        <h2>第6条（コンテンツの権利）</h2>
        <ol>
          <li>
            本アプリ内で提供されるすべてのコンテンツ（テキスト、画像、動画、プログラムコード、その他の著作物を含むがこれに限られない）に関する著作権およびその他の知的財産権は、開発者に帰属します。
          </li>
          <li>
            ユーザーは、開発者の明示的な許可なく、本アプリ内のコンテンツを複製、改変、再配布、販売、貸与、または他の方法で使用することはできません。
          </li>
        </ol>

        <h2>第7条（コミュニティ機能および投稿内容）</h2>
        <ol>
          <li>
            本アプリには、ユーザー同士が交流できるコミュニティ機能が含まれています。ユーザーは、他のユーザーに対して不快感を与えるような投稿を行ってはなりません。
          </li>
          <li>
            以下に該当する投稿は固く禁じられており、開発者の判断により、投稿の削除およびアカウントの停止または剥奪の措置が取られることがあります。
            <ul>
              <li>公序良俗に反する内容</li>
              <li>他のユーザーに対する誹謗中傷や嫌がらせ</li>
              <li>差別的または攻撃的な言葉</li>
              <li>虚偽の情報の拡散</li>
              <li>不適切な広告やスパム行為</li>
              <li>出会いを目的とした投稿</li>
              <li>本人や他者の個人情報または機密情報の公開</li>
            </ul>
          </li>
          <li>
            開発者は、上記に該当する行為に対して、ユーザーへの事前の通知なしに適切な措置を講じる権利を有します。
          </li>
        </ol>

        <h2>第8条（情報の漏洩についての注意）</h2>
        <ol>
          <li>
            本アプリの利用により、情報が漏洩した場合、ユーザーの日記の内容が第三者に漏れる可能性があります。
          </li>
          <li>
            開発者は、情報漏洩に関するいかなる損害についても、一切の責任を負いません。
          </li>
        </ol>

        <h2>第9条（ユーザーIDおよびパスワードの管理）</h2>
        <ol>
          <li>
            ユーザーは、自己の責任においてユーザーIDおよびパスワードを適切に管理するものとします。
          </li>
          <li>
            ユーザーは、ユーザーIDおよびパスワードを第三者と共有してはなりません。
          </li>
          <li>
            ユーザーIDおよびパスワードが第三者に使用されたことにより生じた損害について、開発者は一切の責任を負いません。
          </li>
        </ol>

        <h2>第10条（禁止事項）</h2>
        <ol>
          <li>ユーザーは、以下の行為を行ってはなりません。</li>
          <ul>
            <li>法令または公序良俗に違反する行為</li>
            <li>他のユーザーまたは第三者に不利益、損害を与える行為</li>
            <li>本アプリの運営を妨害する行為</li>
            <li>不正アクセスまたはこれを試みる行為</li>
          </ul>
        </ol>

        <h2>第11条（お問い合わせ）</h2>
        <ol>
          <li>
            本アプリに関する問い合わせは、アプリ内の問い合わせフォームから行うものとします。
          </li>
        </ol>

        <h2>第12条（免責事項）</h2>
        <ol>
          <li>
            開発者は、本アプリに関して生じたいかなる損害についても、一切の責任を負いません。
          </li>
          <li>
            開発者は、本アプリが中断、中止、または変更されることによって生じるいかなる損害についても責任を負いません。
          </li>
        </ol>

        <h2>第13条（準拠法および管轄）</h2>
        <ol>
          <li>本規約の解釈および適用は、日本法に準拠します。</li>
          <li>
            本アプリに関して生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </li>
        </ol>
      </div>
      <Link to="/" className="back-link">
        戻る
      </Link>
    </div>
  );
}

export default TermsOfService;
