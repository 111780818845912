import React from "react";
import "./PrivacyPolicy.scss";
import { Link } from "react-router-dom";

function PrivacyPolicyEn() {
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI Diary Privacy Policy</h1>
      </div>

      <div className="content-area">
        <p>Last updated: 2024/9/10</p>
        <br />
        <p>
          This privacy policy explains the policies regarding the information
          collected from users, how it is used, and the rights of users by
          aidiary (hereinafter referred to as "the Developer"), who provides the
          AI Diary (hereinafter referred to as "this App"). By using this App,
          users are deemed to have agreed to this privacy policy.
        </p>

        <h2>1. Information Collected</h2>
        <p>This App collects the following personal information:</p>
        <ul>
          <li>Email address</li>
          <li>Year of birth</li>
          <li>Gender</li>
        </ul>
        <p>
          Additionally, the diary content created by users using this App is
          stored on the server. Location information and device information are
          not collected.
        </p>

        <h2>2. How Information is Used</h2>
        <p>
          The Developer uses the collected information for the following
          purposes:
        </p>
        <ul>
          <li>Account management</li>
          <li>Sending notification emails from the operation team</li>
          <li>Generating diaries using AI</li>
          <li>Displaying advertisements</li>
          <li>Analyzing app usage</li>
        </ul>

        <h2>3. Information Sharing</h2>
        <p>
          The Developer shares user information with the following third
          parties:
        </p>
        <ul>
          <li>
            <strong>OpenAI</strong>: For generating diaries using AI
          </li>
          <li>
            <strong>Google</strong>: For displaying advertisements and account
            management
          </li>
        </ul>
        <p>
          When displaying advertisements, user behavior data is shared with
          advertisers and may be used for targeted advertising.
        </p>

        <h2>4. User Rights</h2>
        <p>
          Users have the right to request access, correction, and deletion of
          their information. These requests can be made through the settings
          within this App or via the contact form.
        </p>

        <h2>5. Privacy of Minors</h2>
        <p>
          There are no age restrictions for this App, but users under the age of
          18 must obtain parental consent before using this App. The Developer
          prohibits the use of this App by minors who have not obtained parental
          consent.
        </p>

        <h2>6. Data Retention Period</h2>
        <p>
          The Developer retains the collected information until the user deletes
          it through operations within this App. If the user deletes their
          account, the Developer will completely delete all related data.
        </p>

        <h2>7. Changes to the Privacy Policy</h2>
        <p>
          The Developer may change this privacy policy as necessary. If changes
          are made, users are deemed to have agreed to the revised policy.
          Important changes will be notified within this App.
        </p>

        <h2>8. Contact Information</h2>
        <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
      </div>
      <Link to="/en/" className="back-link">
        Back
      </Link>
    </div>
  );
}
export default PrivacyPolicyEn;
