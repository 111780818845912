import React from "react";
import "./FAQ.scss";
import { Link } from "react-router-dom";

function FAQ() {
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">FAQ</h1>
      </div>

      <div className="content-area">
        <h2>Q1.アプリは無料で使えますか？</h2>
        <p>A. 無料で使えます。</p>
        <h2>Q2.なぜ広告が表示されるのですか？</h2>
        <p>
          A.本アプリは無料で提供していますが、開発費やサーバー運用コスト、AIサービスの利用コストなどを賄う必要があり、広告を表示しています。
        </p>
        <h2>Q3.利用可能な端末は？</h2>
        <p>A.本アプリはAndroidバージョン10以上の端末でテストしています。</p>
        <h2>Q4.問い合わせ方法は？</h2>
        <p>
          A.本アプリの「設定・その他」の「問い合わせ」から問い合わせを行ってください。
          <br />
          　メールでの問い合わせが必要な場合は{" "}
          <a href="mailto:support@ai-diary.net">support@ai-diary.net</a>{" "}
          へご連絡ください。
        </p>
        <h2>Q5.不具合を見つけた場合は？</h2>
        <p>
          A.本アプリの「設定・その他」の「問い合わせ」からご連絡ください。
          <br />
          　機種名や詳細な状況、再現方法をご記載ください。
        </p>
        <h2>Q6.日記をGoogleドライブなどにバックアップする方法はありますか？</h2>
        <p>
          A.日記のシェア機能を使ってローカル端末や他のサービスにバックアップすることができます。
          <br />
          　バックアップしたい日記を選択後、右上のシェアボタンをタップしてバックアップしたいサービスを選んでください。
          <br />
          　※写真はシェア対象外です。
        </p>
      </div>
      <Link to="/" className="back-link">
        戻る
      </Link>
    </div>
  );
}
export default FAQ;
