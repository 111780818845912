import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopPage from "./TopPage";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import HowToDeleteData from "./HowToDeleteData";
import TopPageEn from "./TopPageEn";
import HowToDeleteDataEn from "./HowToDeleteDataEn";
import PrivacyPolicyEn from "./PrivacyPolicyEn";
import TermsOfServiceEn from "./TermsOfServiceEn";
import FAQ from "./FAQ";
const App = () => {
  // 各ページのパス設定
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<TopPage />} />
        <Route path={"/tos"} element={<TermsOfService />} />
        <Route path={"/privacy"} element={<PrivacyPolicy />} />
        <Route path={"/deletedata"} element={<HowToDeleteData />} />
        <Route path={"/faq"} element={<FAQ />} />
        <Route path={"/en/"} element={<TopPageEn />} />
        <Route path={"/en/tos"} element={<TermsOfServiceEn />} />
        <Route path={"/en/privacy"} element={<PrivacyPolicyEn />} />
        <Route path={"/en/deletedata"} element={<HowToDeleteDataEn />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
